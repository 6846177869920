import React from 'react'
import './style.less';
import Place from "../Place";
import { Row } from 'antd';

class PlacesToGo extends React.Component {
    render() {

        let linkSlug = "/places-to-go/";

        if(this.props.locale === "cy") {
            linkSlug = "/cy/lleoedd-i-fynd/"
        }

        return (
            <div className="places-to-go">
                <Row />
                <div className="place-listing">
                    {this.props.places && this.props.places.map((place) => {
                        return <React.Fragment>
                            {place.node.title != this.props.hide &&
                            <Place
                                image={place.node.acf.image.localFile.childImageSharp.fluid}
                                name={place.node.title}
                                blurb={place.node.acf.excerpt}
                                btnLink={linkSlug + place.node.slug}
                            /> }
                        </React.Fragment>
                    })}
                </div>
            </div>
        );
    }
}

export default PlacesToGo