import React, {Component} from 'react'
import Wrapper from '../components/Wrapper';
import { graphql } from 'gatsby';
import PageHeader from "../components/PageHeader";
// import language from "../../data/language.yaml";
import ExternalContent from '../components/ExternalContent';
import {Col, Row, Carousel, Pagination} from "antd";
import './blog.less';
import './place.less';
import Img from "gatsby-image";
import language from "../../data/language.yaml";
import MemberListingItem from "./template-member-list";
import PlaceLocationList from "../components/PlaceLocationList";
import DiscoverSnowdoniaMap from "../components/DiscoverSnowdoniaMap";
import PlacesToGo from "../components/PlacesToGo";

class PlaceToGoPost extends Component {
    render() {
        let modules = buildPostModules(this.props.data.wordpressWpPlace.acf.content_place);

        const post = this.props.data.wordpressWpPlace;
        const acfFields = this.props.data.wordpressWpPlace.acf;

        const places = this.props.data.allWordpressWpPlace.edges.filter(({node}) => {
            return !node.hasOwnProperty('translations') || post.translations.current === node.translations.current;
        });;

        let seoTitle = acfFields.meta_title;
        let seoDescription = acfFields.meta_description;
        if (seoTitle===''){
            seoTitle = post.title;
        }

        let path = this.props.data.wordpressWpPlace.slug;
        if (path === 'home') path = '';
        // add a trailing slash
        if (path.substr(-1) !== '/' && path !== '') path += '/';

        let memberListTypes = [
            {
                "en": {
                    "title": "Best things to do",
                    "type": ["attractions"]
                },
                "cy": {
                    "title": "(cy)Best things to do",
                    "type": ["atyniadau"]
                }
            },
            {
                "en": {
                    "title": "Best places to eat & stay & shop",
                    "type": ["accommodation", "retail", "eateries"]
                },
                "cy": {
                    "title": "(cy)Best places to eat & stay & shop",
                    "type": ["llety", "manwerthu", "bwytai"]
                }
            },
        ];

        const settings = {
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            fade: true,
            autoplaySpeed: 10000,
            swipeToSlide: true,
        };

        return (
            <Wrapper
                title={seoTitle}
                description={seoDescription}
                og_title={acfFields.og_title}
                og_description={acfFields.og_description}
                og_image={acfFields.og_image ? acfFields.og_image.localFile.childImageSharp.original.src : null}
                twitter_card_type={acfFields.twitter_card_type}
                twitter_title={acfFields.twitter_title}
                twitter_description={acfFields.twitter_description}
                twitter_image={acfFields.twitter_image ? acfFields.twitter_image.localFile.childImageSharp.original.src : null}
                path={`/${path}`}
                locale={post.translations.current}
                translations={post.translations}
            >
                <div className="place-page">
                <PageHeader
                    title = {post.title}
                    videoID = {post.acf.banner_video}
                    image = {post.acf.image.localFile.childImageSharp.fluid}
                    slides = ""
                    headerColor = "primary"
                    // options: primary, secondary, orange, teal, yellow
                    headerStyle = "cutout"
                    // options: landing, cutout, cutout-small, full
                    back = "true"
                    date = ""
                />
                <div className="blog-post is-place">
                    <Row gutter={70}>
                        <Col xs={{span: 24}} md={{span: 20, offset: 2}} lg={{span: 10, offset: 1}}>
                            <h2>{post.title}</h2>
                            <ExternalContent><div dangerouslySetInnerHTML={{__html: post.acf.place_blurb }} /></ExternalContent>
                        </Col>
                        <Col xs={{span: 24}} md={{span: 20, offset: 2}} lg={{span: 12, offset: 1}}>
                            <Carousel {...settings} >
                                {post.acf.image_slider && post.acf.image_slider.map((image) => {
                                    return <div className="slide" key={image.id}>
                                        { image != null ?
                                            <Img fluid={image.localFile.childImageSharp.fluid} />
                                            : null }
                                    </div>
                                })}
                            </Carousel>
                        </Col>
                    </Row>
                </div>

                <div className="map-wrapper">
                    <DiscoverSnowdoniaMap
                        locale={post.translations.current}
                        showFilterPrompt={false}
                        region={post}
                    />
                </div>

                <div className="place-members">
                    <h2>Here's our list of the top things to do and see in {post.title}<span></span></h2>
                    { memberListTypes.map((item) => {
                        return <PlaceLocationList
                            key={post.ID + item[post.translations.current].type}
                            locale={post.translations.current}
                            place={post}
                            title={item[post.translations.current].title}
                            type={item[post.translations.current].type}
                        />
                    })}
                </div>

                <PlacesToGo places={places} locale={post.translations.current} hide={post.title}/>
                </div>
            </Wrapper>
        )
    }
}

function buildPostModules (modules){
    let response = [];
    for (let module in modules){

        const settings = {
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            fade: true,
            autoplaySpeed: 10000,
            swipeToSlide: true,
        };

        const moduleType = modules[module].__typename;
        let content = '';

        switch (moduleType){

            case 'WordPressAcf_full_text':
                content = <ExternalContent key={modules[module].id}><div dangerouslySetInnerHTML={{__html: modules[module].text }} /></ExternalContent>
                break;

            case 'WordPressAcf_image_slider':
                content = <Carousel {...settings} key={modules[module].id} >
                    {modules[module].images && modules[module].images.map((image) => {
                        return <div className="slide" key={image.id}>
                            { image != null ?
                                <Img fluid={image.localFile.childImageSharp.fluid} />
                                : null }
                        </div>
                    })}
                </Carousel>
                break;

            default:
                // Unknown module
                break;
        }
        if (content) response.push(content);
    }
    return response;
}

export const pageQuery = graphql`
  query QueryPlacePostDetail($id: String!) {
    wordpressWpPlace(id: { eq: $id }) {
      id
      wordpress_id
      slug
      title
      date(formatString: "YYYY-MM-DDTHH:MM:SS")
      humanDate: date(formatString: "Do MMM")
      translations {
        current
        en
        cy
      }
      acf {
        banner_video
        image {
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
                fluid(quality: 80, maxWidth: 1440, maxHeight: 640) {
                    ... GatsbyImageSharpFluid
                }
            }
          }    
        }
        kml_region_file
        latitude
        longitude
        zoom
        excerpt
        meta_title
        meta_description
		og_title
        og_description
        og_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        }
        twitter_title
        twitter_card_type
        twitter_description
        twitter_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        }
        place_blurb
        image_slider {
          id
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
              fluid(maxHeight: 450, quality: 80) {
                ... GatsbyImageSharpFluid
              }
            }
          }
        }
		content_place {
          __typename
          ...on WordPressAcf_full_text {
            id
            text
          }
          ...on WordPressAcf_image_slider {
            id
            images {
              id
              localFile {
                childImageSharp {
                  original {
                    width
                    height
                    src
                  }
                  fluid(maxHeight: 450, quality: 80) {
                    ... GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    },
    allWordpressWpPlace {
      edges {
        node {
        id
        slug
        title
        date(formatString: "YYYY-MM-DDTHH:MM:SS")
        acf {
          image {
            localFile {
              childImageSharp {
                original {
                  src
                  width
                  height
                }
                fluid(maxWidth: 720, maxHeight:640, quality: 80, cropFocus: CENTER) {
                  ... GatsbyImageSharpFluid
                }
              }
            }    
          }
          excerpt
        }
        translations {
            current
            en
            cy
        }
      }
      }
    }
  }
`

export default PlaceToGoPost
