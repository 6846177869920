import React, {Component} from 'react';
import {graphql, StaticQuery} from "gatsby";
import {Col, Row, Typography} from "antd";
import Link from "../Link";
import Img from "gatsby-image";
import './style.less';
import language from "../../../data/language.yaml";
import {shuffle} from "../../services/helpers";

class PlaceLocationList extends Component {
    locationTypes = {};

    state = {
        members: []
    }

    componentDidMount = function () {
        let memberIDs = [];
        let members = [];
        let placeID = parseInt(this.props.place.wordpress_id);
        this.props.locations.forEach((location) => {
            if (this.props.type && !this.props.type.includes(location.parent_location_type)) {
                return false;
            }
            if (location.translations.current !== this.props.locale) {
                return false;
            }
            if (!location.acf.places) {
                return false;
            }
            let inRegion = false;
            for(let i = 0; i < location.acf.places.length; i++) {
                if (parseInt(location.acf.places[i].place) === placeID) {
                    inRegion = true;
                    break;
                }
            }
            if (!inRegion) {
                return false;
            }
            let member = location.acf.member;
            if (!memberIDs.includes(member.wordpress_id)) {
                member.type = this.locationTypes[location.acf.type];
                members.push(member)
                memberIDs.push(member.wordpress_id);
            }
        });

        this.setState({
            members: shuffle(members),
        });
    }

    constructor(props) {
        super(props);

        this.props.allTypes.forEach(locationType => {
            this.locationTypes[locationType.wordpress_id] = locationType;
        });
    }

    render() {
        return (
            <div className="member-list">
                <h3>{this.props.title}</h3>
                {this.state.members.map((member) => {
                    let linkLocation = false;
                    if (parseInt(member.membership_level) < 3) {
                        linkLocation = member.path;
                    } else {
                        linkLocation = member.website_url;
                    }

                    return <div key={member.wordpress_id} className="place-location-listing-item" data-color={member.type.acf.listing_colour}>
                        {member && member.featured_image && (
                            <Link to={linkLocation}>
                                <Img
                                    fluid={member.featured_image.localFile.childImageSharp.fluid}/>
                            </Link>
                        )}
                        <span className="content-wrap">
                            <span className="text-wrap">
                                <Link to={member.path}><span className="title" dangerouslySetInnerHTML={{__html: member.name}} /></Link>
                                <span className="desc">
                                    {member.snippet}
                                </span>
                            </span>
                        </span>
                    </div>
                })}
            </div>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query PlaceLocationList {
                allWordpressWpLocation {
                    nodes {
                        id
                        parent_location_type
                        acf {
                            address_1
                            address_2
                            name
                            postcode
                            town
                            latitude
                            longitude
                            places {
                                place
                            }
                            type
                            member {
                                membership_level
                                name
                                path
                                snippet
                                website_url
                                wordpress_id
                                featured_image {
                                    localFile {
                                        childImageSharp {
                                            fluid(maxWidth: 680, maxHeight: 350, quality: 80) {
                                                ...GatsbyImageSharpFluid
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        translations {
                            cy
                            en
                            current
                        }
                    }
                }
                allWordpressWpLocationType {
                    nodes {
                        acf {
                            class
                            label
                            marker_icon {
                                localFile {
                                    url
                                }
                            }
                            listing_colour
                        },
                        translations {
                            current
                            cy
                            en
                        },
                        wordpress_id
                        wordpress_parent
                    }
                }
            }
        `}
        render={(data) => {
            return <PlaceLocationList locations={data.allWordpressWpLocation.nodes}
                                      allTypes={data.allWordpressWpLocationType.nodes}
                                      {...props} />
        }}
    />
)
